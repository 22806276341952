<template>
  <v-btn v-if="create" @click="click" color="error" small>{{ label | t }}</v-btn>
</template>

<script>
import listDataAction from "@/components/list/listDataAction/listDataAction.js";
export default {
  mixins: [listDataAction],
  computed: {
    // 不可解綁主要管理員
    // show() {
    //   return !this.row.isMaster
    // },
    popupContent() {
      if(this.config.contentKey) return this.row[this.config.contentKey]
      return this.row[this.listTargetKey]
    },
  },
  methods: {
    click() {
      this.$apopup.base({
        title: this.$t('relieve.confirm'),
        content: this.popupContent,
        applyCallback: async() => {
          this.$store.dispatch("loading/active");
          try {
            await this.listApi.deleteApi(null, this.row);
            await this.listActions.index()
            this.$snotify.success(null, this.$t("action.relieve.success"));
          } catch (err) {
            console.error(err);
            this.$snotify.error(null, this.$t("action.relieve.fail"));
          } finally {
            this.$store.dispatch("loading/close");
          }
        },
      });
    },
  },
};
</script>